/**
    Dev note: to add more fonts or get other configs, check: https://google-webfonts-helper.herokuapp.com/fonts/
 */

/* poppins-300 - latin-ext_latin_devanagari */
@font-face {
    font-display: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300.woff') format('woff'), /* Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-300italic - latin-ext_latin_devanagari */
@font-face {
    font-display: auto;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-300italic.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* poppins-regular - latin-ext_latin_devanagari */
@font-face {
    font-display: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-regular.woff') format('woff'), /* Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-italic - latin-ext_latin_devanagari */
@font-face {
    font-display: auto;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - latin-ext_latin_devanagari */
@font-face {
    font-display: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500.woff') format('woff'), /* Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500italic - latin-ext_latin_devanagari */
@font-face {
    font-display: auto;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600 - latin-ext_latin_devanagari */
@font-face {
    font-display: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600.woff') format('woff'), /* Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600italic - latin-ext_latin_devanagari */
@font-face {
    font-display: auto;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/poppins/poppins-v15-latin-ext_latin_devanagari-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin-ext_latin */
@font-face {
    font-display: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/roboto/roboto-v20-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/roboto/roboto-v20-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/roboto/roboto-v20-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* roboto-300italic - latin-ext_latin */
@font-face {
    font-display: auto;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('/fonts/roboto/roboto-v20-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/roboto/roboto-v20-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/roboto/roboto-v20-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/roboto/roboto-v20-latin-ext_latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-italic - latin-ext_latin */
@font-face {
    font-display: auto;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/roboto/roboto-v20-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/roboto/roboto-v20-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/roboto/roboto-v20-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-italic.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* roboto-500 - latin-ext_latin */
@font-face {
    font-display: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/roboto/roboto-v20-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/roboto/roboto-v20-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/roboto/roboto-v20-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-500.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* roboto-regular - latin-ext_latin */
@font-face {
    font-display: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/roboto/roboto-v20-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/roboto/roboto-v20-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/roboto/roboto-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/roboto/roboto-v20-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500italic - latin-ext_latin */
@font-face {
    font-display: auto;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/roboto/roboto-v20-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/roboto/roboto-v20-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/roboto/roboto-v20-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-500italic.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* roboto-700 - latin-ext_latin */
@font-face {
    font-display: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/roboto/roboto-v20-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/roboto/roboto-v20-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/roboto/roboto-v20-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/roboto/roboto-v20-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin-ext_latin */
@font-face {
    font-display: auto;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/roboto/roboto-v20-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/roboto/roboto-v20-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/roboto/roboto-v20-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/roboto/roboto-v20-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/roboto/roboto-v20-latin-ext_latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}